import React from 'react';
import styled from '@emotion/styled';
import { useSpring, animated } from 'react-spring';
// Assuming an alternative icon or visual for failure, use a placeholder here

// Styled component for the container remains the same
const FailureContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background: linear-gradient(135deg, #fb6e6e, #e377a7);
  color: #fff; /* Adjust text color for better contrast */
`;

// Styled component for the message
const FailureMessage = styled(animated.div)`
  margin-top: 20px;
  font-size: 26px;
  color: #223344; /* Adjust for better visibility if needed */
  font-weight: bold;
`;

const ReturnToGameMessage = styled(animated.div)`
    margin-top: 20px;
    font-size: 22px;
    color: #223344; /* Adjust for better visibility if needed */
    font-weight: bold;
`;

const LoginFailed = () => {
  // Spring animation for the message
  const messageSpring = useSpring({
    to: { opacity: 1, transform: 'translateY(0)' },
    from: { opacity: 0, transform: 'translateY(-20px)' },
    delay: 500,
  });

  const returnMessageSpring = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1000,
  });

  return (
    <FailureContainer>
      {/* Placeholder for failure icon - consider using a custom SVG, image, or another library's icon */}
      <div style={{ fontSize: '128px', color: '#223344' }}>❌</div>
      <FailureMessage style={messageSpring}>
        Login Failed!
      </FailureMessage>
      <ReturnToGameMessage style={returnMessageSpring}>
        Please try logging in again.
      </ReturnToGameMessage>
    </FailureContainer>
  );
}

export default LoginFailed;
