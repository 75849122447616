import React from 'react';
import styled from '@emotion/styled';
import { useSpring, animated } from 'react-spring';
import { Checkmark } from 'react-checkmark';

// Styled component for the container

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  color: #fff; /* Adjust text color for better contrast */
`;

// Styled component for the message
const SuccessMessage = styled(animated.div)`
  margin-top: 20px;
  font-size: 26px;
  color: #223344;
  font-weight: bold;
`;

const ReturnToGameMessage = styled(animated.div)`
    margin-top: 20px;
    font-size: 22px;
    color: #223344;
    font-weight: bold;
`;

const LoginSuccess = () => {
  // Spring animation for the message
  const messageSpring = useSpring({
    to: { opacity: 1, transform: 'translateY(0)' },
    from: { opacity: 0, transform: 'translateY(-20px)' },
    delay: 500,
  });

  const returnMessageSpring = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1000,
  });

  return (
    <SuccessContainer>
      <Checkmark size='256px' color='#223344'/>
      <SuccessMessage style={messageSpring}>
        Login Successful!
      </SuccessMessage>
      <ReturnToGameMessage style={returnMessageSpring}>
        Please return to the game window.
      </ReturnToGameMessage>
    </SuccessContainer>
  );
}

export default LoginSuccess;
