import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import LoginSuccess from './LoginSuccess'; // Assuming you create this component
import LoginFailed from './LoginFailed';

function App() {
  return (
    <Router>
      <div>
        <nav>
          {/* Navigation links or buttons can go here */}
        </nav>
        <Routes>
          <Route path="/login-success" element={<LoginSuccess />} />
          <Route path="/login-failed" element={<LoginFailed />} />
          {/* Other routes here */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;